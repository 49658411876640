import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function AboutUs() {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "about-us.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="about-us" className="about-us section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <h2 className="paragraph-subtitle">O nas</h2>
            <h3 className="paragraph-title">Dobra kawa to nielada wyzwanie.</h3>
            <p>
              Zaopatrzymy Twoją restaurację, kawiarnię lub biuro w wysokiej
              jakości produkty. Posiadamy szeroki asortyment ekspresów do kawy
              spełniających oczekiwania najbardziej wymagających odbiorców.
            </p>
            <p>
              Prowadzimy również serwis ekspresów, które wymagają drugiego życia
              oraz szkolenia personelu w zakresie przygotowywania niezapomnianej
              kawy.
            </p>
            <ul className="decoration-list">
              <li>kawa</li>
              <li>herbata</li>
              <li>sprzedaż, dzierżawa oraz serwis ekspresów</li>
              <li>szkolenia baristyczne</li>
            </ul>
          </div>
          <div className="col-lg-5">
            <Img
              className="mobile-spacing-bottom"
              fluid={query.bannerImg.childImageSharp.fluid}
              alt="Banner"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
