import React from "react";
import { Link } from "gatsby";
import Arrow from "../../images/icons/arrow.svg";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Services() {
  const query = useStaticQuery(graphql`
    query {
      kawa: file(relativePath: { eq: "kawa.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 310, maxHeight: 186) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      herbata: file(relativePath: { eq: "herbata.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 310, maxHeight: 186) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ekspresy: file(relativePath: { eq: "ekspresy.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 310, maxHeight: 186) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section id="offer" className="services white-c padding-bottom">
      <div className="container">
        <h2 className="section-title">OFERTA</h2>
        <p className="section-description">
          Posiadamy szeroką gamę usług i produktów, nasze główne obszary
          działania to:
        </p>
        <div className="row">
          <div className="col-lg-4 single-service">
            <div className="single-service-inner">
              <Img
                className="service-img"
                fluid={query.kawa.childImageSharp.fluid}
                alt="Kawa"
              />
              <h3 className="service-title">Kawy</h3>
              <p className="service-description">
                Kilka miesięcy trwało dobieranie najlepszych mieszanek do naszej
                oferty. Jesteśmy w stanie sprostać różnym upodobaniom! Kawa
                wyrazista, mocna, aromatyczna, czy może delikatna i kremowa?
              </p>
              <span className="more-link">
                Więcej
                <Arrow className="arrow-icon" />
              </span>
              <Link to="/kawy" className="nav-link"></Link>
            </div>
          </div>
          <div className="col-lg-4 single-service">
            <div className="single-service-inner">
              <Img
                className="service-img"
                fluid={query.herbata.childImageSharp.fluid}
                alt="Herbata"
              />
              <h3 className="service-title">Herbaty</h3>
              <p className="service-description">
                Jesteśmy dystrybutorem marki Richmont oraz Sir William's
                na polskim rynku. Proponujemy wysokiej jakości herbaty, bez
                sztucznych aromatów i barwników.
              </p>
              <span className="more-link">
                Więcej
                <Arrow className="arrow-icon" />
              </span>
              <Link to="/herbaty" className="nav-link"></Link>
            </div>
          </div>
          <div className="col-lg-4 single-service">
            <div className="single-service-inner">
              <Img
                className="service-img"
                fluid={query.ekspresy.childImageSharp.fluid}
                alt="Ekspresy"
              />
              <h3 className="service-title">Ekspresy i serwis</h3>
              <p className="service-description">
                W naszej ofercie dostępne są profesjonalne ekspresy zarówno
                kolbowe, jak i automatyczne. Zapoznaj się z naszą ofertą
                ekspresów i wybierz coś dla siebie.
              </p>
              <span className="more-link">
                Więcej
                <Arrow className="arrow-icon" />
              </span>
              <Link to="/ekspresy" className="nav-link"></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
