import React from "react";

export default function FacebookPosts() {
  return (
    <section id="facebook-posts" className="section-padding gray-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="text-box-padding-xs-right">
              <h2 className="paragraph-subtitle">Social media</h2>
              <h3 className="paragraph-title">
                Bądź na bieżąco i śledź nasz profil
              </h3>
              <p>
                Zapraszamy do śledzenia naszego konta na Facebooku. Dzielimy się
                tam przydatną wiedzą dla baristów i nietylko. Dowiesz się o
                naszej aktualnej ofercie oraz ciekawostkach ze świata kaw i
                herbat.
              </p>
              <p>
                Mamy nadzieję, że weźmiesz aktywny udział w naszej społeczności.
                Cieszymy się na każdy komentarz i interakcję. Do zobaczenia na
                Facebooku!
              </p>
            </div>
          </div>
          <div className="col-lg-6 facebook-widget"></div>
        </div>
      </div>
    </section>
  );
}
