import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function Banner() {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const scrollView = () => {
    document
      .getElementById("offer")
      .scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  return (
    <section className="banner-section padding-bottom-xs">
      <BackgroundImage
        Tag="div"
        className="banner-content"
        fluid={[
          `linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.50))`,
          query.bannerImg.childImageSharp.fluid,
        ]}
        backgroundColor={`#040e18`}
      >
        <div className="container">
          <div className="banner-text-container">
            <h1>
              MAMY OGROMNE <br />
              SERCE DO KAWY
            </h1>
            <p>
              Wiemy co zrobić żeby każda Twoja kawa była niezapomniana.
              Wyrazisty aromat i intensywny smak zwieńczony delikatną cremą. Nie
              tylko najlepsze ziarno i urządzenia, ale i profesjonalny serwis i
              szkolenia.
            </p>
            <button className="button" onClick={scrollView}>
              Zobacz więcej
            </button>
          </div>
        </div>
      </BackgroundImage>
    </section>
  );
}
