import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/global/Seo"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"

import Banner from "../components/home/Banner"
import AboutUs from "../components/home/AboutUs"
import Services from "../components/home/Services"
// import Recommendations from "../components/home/Recommendations"
import Clients from "../components/home/Clients"
import FacebookPosts from "../components/home/FacebookPosts"
import CallToAction from "../components/home/CallToAction"
import ContactSection from "../components/home/ContactSection"

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div>
      <SEO title="Home" titleTemplate={site.siteMetadata.title} />
      <Header />
      <Banner />
      <AboutUs />
      <Services />
      <CallToAction />
      <FacebookPosts />
      {/* <Recommendations /> */}
      <Clients />
      <ContactSection isHome={true} />
      <Footer />
    </div>
  )
}
