import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function CallToAction() {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "section-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const scrollView = () => {
    document
      .getElementById("contact")
      .scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  return (
    <BackgroundImage
      Tag="section"
      className="call-to-action white-c text-center section-padding image-section"
      fluid={[
        `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.70))`,
        query.bannerImg.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
    >
      <div className="container">
        <p className="section-subtitle">Napisz do nas</p>
        <h2 className="section-title">Porozmawiajmy o kawie</h2>
        <p className="section-description">
          Świadczymy wszelkie usługi związane z kawą i herbatą w firmach,
          restauracjach i kawiarniach
        </p>
        <button className="button" onClick={scrollView}>
          Napisz do nas
        </button>
      </div>
    </BackgroundImage>
  );
}
