import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
// Import Swiper React components
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, A11y]);

export default function Recommendations() {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "couple.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(height: 60, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      className="clients section-padding"
      fluid={[
        `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.70))`,
        query.bannerImg.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
    >
      <div className="container">
        <h2 className="section-title">PARTNERZY</h2>
        <p className="section-description white-c">
          Zaufali nam między innymi:
        </p>
        <div className="clients-slider slider-container">
          <div className="swiper-button-prev clients-prev"></div>
          <div className="swiper-button-next clients-next"></div>
          <Swiper
            spaceBetween={15}
            slidesPerView="auto"
            navigation={{
              nextEl: ".clients-next",
              prevEl: ".clients-prev",
            }}
            pagination={{ clickable: true }}
          >
            {query.klienci.edges.map((edge, i) => {
              return (
                <SwiperSlide key={i}>
                  <Img
                    fixed={edge.node.childImageSharp.fixed}
                    alt="Klient logo"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </BackgroundImage>
  );
}
